const header = document.getElementsByTagName("header")[0];

const handleScroll = () => {
  if (document.body.getBoundingClientRect().top < -20) {
    header.classList.add("onTop");
    return;
  }
  header.classList.remove("onTop");
};

document.addEventListener("scroll", handleScroll);
